@import "colors";
@import "misc";

html {
  scroll-behavior: smooth;
}

body {
  background: $backgroundColor;
  margin: 0;
  padding-top: calc(35px + 120px);  // .top-bar height + .navigation height
  @media (max-width: $tablet) { padding-top: calc(35px + 74.13px); }  // .top-bar height + .navigation height

  // font default settings
  color: $defaultFontColor;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0.03rem;

  @media (max-width: $mobile) {
    font-size: calc(16px * 0.8);
    // padding-top: 8rem;
  }

  a {
    text-decoration: none;
    color: $secondaryColor;
  }

  h1 {
    color: $primaryColor;
    display: flex;
    justify-content: center;
    font-size: 250%;
    text-align: center;
    margin-top: 0;
    @media (max-width: $mobile) {
      font-size: 10,4px;
    }
  }

  h2 {
    color: $primaryColor;
    @media (max-width: $mobile) {
      font-size: 16px;
    }
  }

  h3 {
    color: $primaryColor;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.6;
    @media (max-width: $mobile) {
      font-size: 14px;
    }
  }
}

.btn {
  background-color: $primaryColor;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  border-radius: 6px;
  transition: all 0.2s;
  line-height: 20px;
  font-weight: 400;
  color: white;
  padding: 12px 16px;
  min-width: 140px;
  text-align: center;
  margin-top: 1.5rem;
  @media (max-width: $mobile) {
    font-size: 10px;
  }

  &:hover {
    opacity: 0.9;
  }
}

.container {
  padding-top: 6em;
  display: flex;
  width: 100%;
  justify-content: center;

  @media (max-width: $mobile) {
    padding-top: 2em;
  }

  .wrapper {
    flex-basis: 90rem;
    padding: 0 1rem;

    h2 {
      display: flex;
    }

    img {
      margin: 3rem 0;
    }

    .center {
      display: flex;
      justify-content: center;
    }

    .qImg-container{
      position: relative;
      // width: 160px;
      width: 100%;
      height: 5.7142857143rem;
      overflow: hidden;
      margin-top: 20px;
      margin-bottom: 20px;

      .qImg {
        margin: 0 0;
        position: absolute;
        display: block;
        background-size: contain;
        background-position: center;
        float: left;
        width: 160px;
        display: flex;
        align-self: center;
        background-repeat: no-repeat;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    p {
      display: flex;
      font-size: 120%;

      @media (max-width: $mobile) {
        flex-direction: column;
      }
    }
  }
}

.column-container {
  display: flex;
  justify-content: space-evenly;
  
  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: center;
  }
}

.column-container-wrap {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  
  @media (max-width: $tablet) {
    flex-direction: column;
    align-items: center;
  }
}

.grey-line {
  border: 0;
  height: 1px;
  border-top: 1px solid #ebebeb;
  display: block;
  position: relative;
  top: 1px;
  width: 100%;
  border-color: #ebebeb;
  margin: 0;
  padding: 0;
}

.section-banner {
  padding: 5rem 0;
  background-color: $secondaryColor;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  
  @media (max-width: $mobile) {
    flex-direction: column;
    padding: 3rem 0;
  }
}

.tablet-banner {
  @media (max-width: $tablet) {
    flex-direction: column;
  }
}

.banner-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.white {
  color: white;
}

.blue {
  color: $secondaryColor;
}

.large {
  font-size: 200%;
}

.medium {
  font-size: 150%;
}

.map-container {
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  
  p {
    font-size: 150%;
  }

  @media (max-width: $mobile) {
    padding: 3rem 0;
  }
}

.border-box {
  width: 300px;
  height: 250px;
  padding: 0 5rem;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  h1 {
    margin-top: 2rem;
    margin-bottom: 0;
    font-size: 375%;
    line-height: 1.2;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 3rem;
  }
  
  @media (max-width: $mobile) {
    width: 100px;
    height: 350px;
  }
}

.border-box-parent {
  margin: 2rem 2rem;
  @media (max-width: $tablet) {
    margin: 2rem 0rem;
  }
}

/* Slideshow container */
.slideshow-container {
  position: relative;
  background: white;
}

/* Slides */
.mySlides {
  display: none;
  height: 450px;
  padding: 20px 80px;
  text-align: center;
  flex-direction: column;
  
  @media (max-width: $tablet) {
    padding: 20px 40px;
    height: 650px;
  }
  
  @media (max-width: $mobile) {
    padding: 20px 10px;
    height: auto;
  }
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: #888;
  font-weight: bold;
  font-size: 16px;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  position: absolute;
  inset-inline-end: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

/* The dots/bullets/indicators */
.dot2 {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* The dot/bullet/indicator container */
.dot-container {
  text-align: center;
  padding: 20px;
  background: white;
}

/* Add a background color to the active dot/circle */
.active,
.dot:hover {
  background-color: #717171;
}

/* Slides */
.mySlides2 {
  display: none;
  height: 350px;
  padding: 20px 80px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (max-width: $tablet) {
    img {
      height: 200px;
      width: auto;
    }
  }
  
  @media (max-width: $mobile) {
    height: 35%;
    img {
      height: 80px;
      width: auto;
    }
  }
}

.sliderImage {
  width: 400px;
  height: auto;
}

.border-image {
  border: solid;
  border-color: $secondaryColor;
  border-width: 0.3rem;
  border-radius: 10px;
}

.space-between-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.banner-content-top {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-margin {
  margin-top: 7rem;
}

.tablet-disappear {
  @media (max-width: $tablet + 120px) {
    display: none;
  }
}
